<template>
    <div class="index">
        <sections-hero data-section="hero" class="z-50" />
        <sections-services data-section="services" class="z-40" />
        <sections-how-it-works data-section="how-it-works" class="z-40" />
        <sections-mission-teaser data-section="mission-teaser" />
        <sections-social-projects data-section="social-projects" class="z-40" />
        <sections-recyclables data-section="recyclables" class="z-40" />
        <sections-business-solutions
            data-section="business-solutions"
            class="z-40"
        />
        <sections-blog-preview data-section="blog-preview" class="z-40" />
        <sections-faq
            data-section="faq"
            class="z-40"
            category-filter="Top Fragen"
            :show-button="true"
        />
    </div>
</template>

<script>
import _each from 'lodash/each.js'

export default {
    setup() {
        useHead({
            title: `Dein kostenloser Abholservice für Altkleider und andere Wertstoffe aus ganz Deutschland`,
            meta: [
                {
                    name: 'description',
                    content: `Mit dem Abholservice von recyclehero kannst du kostenlos Altkleider abholen lassen. Wir kommen in ganz Deutschland klimaneutral an deine Haustür und bringen deine Kleiderspende wieder in den Kreislauf!`,
                },
            ],
        })
    },

    mounted() {
        const sections = []

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio >= 0.5) {
                        const index = sections.indexOf(entry.target)
                        const name = entry.target.getAttribute('data-section')

                        this.$tracker.emitScroll(index + 1 + ': ' + name)
                        observer.unobserve(entry.target)
                    }
                })
            },
            { threshold: [0.5] },
        )

        _each(this.$el.children, (section) => {
            sections.push(section)
            observer.observe(section)
        })
    },
}
</script>

<style lang="scss" scoped></style>
