<template>
    <sections-base
        :class="['lp-hero', { show }]"
        background-class="bg-ocean-600"
    >
        <div class="content">
            <header>
                <headline class=""
                    >Wir bringen deine<br />Wertstoffe zurück in <br />den
                    Kreislauf!</headline
                >
                <h2 class="subheadline">
                    Du möchtest deine Altkleider oder andere Wertstoffe abholen
                    lassen? Wir kommen in ganz Deutschland kostenlos und
                    klimaneutral an deine Haustür.
                </h2>
                <h2 class="subheadline mt-4">
                    Das Beste dabei? Wir finden eine möglichst lokale
                    Wiederverwendung <br />
                    für deine Wertstoffe!
                </h2>
            </header>
            <zip-code-check
                class="ml-2"
                v-bind="settings"
                @result="handleZipCodeCheckResult"
            />
        </div>
        <div :class="['hero-image-container', { show }]">
            <icon name="scribbles-pow" class="" />
            <icon name="scribbles-star1" class="" />
            <icon name="scribbles-star2" class="" />
            <icon name="scribbles-wooosh" class="" />
            <picture>
                <img
                    class="hero-image"
                    src="/images/clothes/RH-website-header-altkleider_01.png"
                    width="802"
                    height="567"
                />
            </picture>
        </div>
    </sections-base>
</template>

<script>
import useOrderStore from '~/stores/order.js'
import useUserStore from '~/stores/user.js'

export default {
    inject: ['showModal'],
    async setup() {
        const [orderTools, orderStore, userStore] = await Promise.all([
            useOrderTools(),
            useOrderStore(),
            useUserStore(),
        ])

        return {
            orderTools,
            orderStore,
            userStore,
        }
    },

    data() {
        return {
            settings: {
                zipCode: '',
                city: '',
                customerGroup: Enums.customerGroups.private,
                tourType: '',
            },

            show: false,
        }
    },

    mounted() {
        this.settings = this.orderStore.settings
        this.settings.tourType = ''

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry) {
                    if (entry.intersectionRatio >= 0.75) {
                        this.show = true
                    } else if (entry.intersectionRatio <= 0) {
                        this.show = false
                    }
                }
            },
            { threshold: [0, 0.75] },
        )
        observer.observe(this.$el)
    },

    methods: {
        handleZipCodeCheckResult(serviceAvailable, _data) {
            const vm = this

            if (!serviceAvailable) {
                vm.orderTools.showLocationNotSupportedModal({
                    closable: true,
                })
                // vm.showModal(
                //     {
                //         component: LocationNotSupportedModal,
                //         params: {
                //             zipCode: vm.settings.zipCode,
                //             customerGroup: vm.settings.customerGroup,
                //         },
                //     },
                //     { closeMethods: ['button'] },
                // )

                return
            }

            vm.orderTools.showChooseTourTypeModal({}, true)
        },
    },
}
</script>

<style lang="scss" scoped>
:deep(.section-base-background) {
    .icon {
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

:deep(.section-base-inner) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 28rem;
}

.content {
    display: flex;
    flex-flow: column;
    width: 100%;
}

header {
    margin-bottom: 3rem;

    :deep(.headline) {
        @apply text-2xl;
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
        text-align: left;
    }
}

.subheadline {
    @apply text-lg;
    @apply font-medium;
    line-height: 1.2;
    margin-bottom: 0;
    padding-left: 0.5rem;
    width: 90%;
}

:deep(.zip-code-check) {
    h3 {
        width: 63%;
    }
}

.headline {
    @apply text-2xl;
    text-align: left;
}

.hero-image-container {
    display: none;
    position: absolute;
    z-index: 5;
    bottom: 0;
    right: 0;
    width: 38%;

    &.show {
        .hero-image {
            opacity: 1;
            transform: translate(0%, 0%) scale(1);
        }

        .icon {
            opacity: 1;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    .icon {
        position: absolute;
        display: block;
        color: #fff;
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.5);
        transition: opacity 0.3s 0s,
            transform 0.3s 0s theme('transitionTimingFunction.easeOutCubic');
    }

    .scribbles-pow {
        left: 3%;
        top: 53%;
        width: 8%;
        transition-delay: 0.3s, 0.3s;
    }

    .scribbles-star1 {
        left: 19%;
        top: 26%;
        width: 10%;
        transition-delay: 0.45s, 0.45s;
    }

    .scribbles-star2 {
        left: 75%;
        top: 16%;
        width: 13%;
        transition-delay: 0.6s, 0.6s;
    }

    .scribbles-wooosh {
        left: 85%;
        top: 38%;
        width: 25%;
        transition-delay: 0.15s, 0.15s;
    }
}

.hero-image {
    display: block;
    width: 100%;
    height: auto;
    opacity: 0.01;
    transform: translate(0%, 15%) scale(0.85);
    transition: opacity 0.3s 0s,
        transform 0.3s 0s theme('transitionTimingFunction.easeOutCubic');
}

@media screen and (min-width: 380px) {
    :deep(.section-base-inner) {
        min-height: 28rem;
    }

    header {
        width: 100%;
        margin-bottom: auto;

        :deep(.headline) {
            width: 60%;
            @apply text-2xl;
            margin-bottom: 1.5rem;
        }
    }

    .subheadline {
        width: 79%;
    }

    :deep(.zip-code-check) {
        width: 70%;
        max-width: 18rem;

        h3 {
            width: auto;
            margin-bottom: 1rem;
        }
    }

    .hero-image-container {
        position: absolute;
        z-index: 5;
        bottom: 0;
        right: 2%;
        width: 40%;
    }
}

@screen sm {
    :deep(.section-base-inner) {
        min-height: 30rem;
    }

    .content {
        width: 95%;
        margin: 0 auto;
    }

    header {
        margin: 0 0 2.5rem;
        width: 90%;

        :deep(.headline) {
            width: auto;
            @apply text-4xl;
            margin-bottom: 1.5rem;
        }
    }

    .subheadline {
        @apply text-xl;
        width: 75%;
    }

    :deep(.zip-code-check) {
        width: auto;
        max-width: none;
    }

    .hero-image-container {
        display: block;
        bottom: 0%;
        right: var(--section-padding-x);
        width: 33%;
    }

    .headline {
        @apply text-3xl;
    }
}

@screen md {
    :deep(.section-base-inner) {
        min-height: 30rem;
    }

    .headline {
        width: 70%;
    }

    .content {
        width: 95%;
        margin-left: 5%;
        margin-bottom: 2rem;
    }

    header {
        width: 85%;

        :deep(.headline) {
            @apply text-4xl;
            margin-bottom: 1.5rem;
        }
    }

    .subheadline {
        width: 75%;
    }

    .hero-image-container {
        right: var(--section-padding-x);
        width: 34%;
    }
}

@screen lg {
    :deep(.section-base-inner) {
        min-height: 34rem;
    }

    .content {
        margin-bottom: 0;
    }

    header {
        width: 72%;

        :deep(.headline) {
            @apply text-5xl;
        }
    }

    .headline {
        @apply text-4xl;
    }

    .subheadline {
        width: 65%;
    }

    .hero-image-container {
        right: calc(var(--section-padding-x) + 2.5%);
        width: 30%;
    }
}

@screen xl {
    :deep(.section-base-inner) {
        min-height: 38rem;
    }

    .headline {
        width: 60%;
    }

    .content {
        width: 80%;
        margin-left: 5%;
        margin-bottom: 0;
    }

    header {
        width: 100%;

        :deep(.headline) {
            width: 88%;
            @apply text-6xl;
            margin-bottom: 2.5rem;
        }
    }

    .hero-image-container {
        width: 38%;
    }
}
</style>
