<template>
    <sections-base class="clothes-about" background-class="bg-ocean-300">
        <header>
            <headline class=""
                >BRING DEINE SECONDHAND-ARTIKEL UND WERTSTOFFE ZURÜCK IN DEN
                KREISLAUF!</headline
            >
        </header>

        <div class="content">
            <div class="row">
                <div class="list">
                    <h3>Altkleider</h3>
                    <p>
                        Wir kümmern uns darum, dass deine Altkleider abhängig
                        von deinem Wohnort den klimafreundlichsten Weg zu
                        unseren Secondhandpartnern finden, die sich um eine
                        möglichst lokale Wiederverwendung kümmern! Alle Services
                        für Altkleider sind einmalig und kostenlos.
                    </p>

                    <div class="button-container">
                        <butty
                            class="accent shadow"
                            :to="{ name: 'old-clothes' }"
                            >Jetzt kostenlos buchen</butty
                        >
                    </div>
                </div>

                <div class="illu">
                    <img src="/images/clothes/altkleider-01.svg" alt="" />
                </div>
            </div>

            <div class="row reverse">
                <div class="list">
                    <h3>Elektrogeräte</h3>
                    <p>
                        In Hamburg holen wir deine Elektrogeräte (Handys,
                        Tablets, Laptops) kostenlos ab. Gib uns deine alten
                        Elektrogeräte mit und trage dazu bei, wertvolle
                        Rohstoffe wieder in den Kreislauf zu bringen! Deine
                        gebrauchten Geräte gehen nicht ins Ausland, sondern
                        bleiben in Deutschland. Abholungen von Elektrogeräten
                        sind einmalig und kostenlos.
                    </p>

                    <div class="button-container">
                        <butty
                            class="accent shadow"
                            :to="{ name: 'electronics' }"
                            >Jetzt kostenlos buchen</butty
                        >
                    </div>
                </div>

                <div class="illu">
                    <img
                        src="/images/recyclables/RH_Icons-Elektro_03.png"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </sections-base>
</template>

<style lang="scss" scoped>
@import '@/assets/config';

header {
    margin-bottom: 2.5rem;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
}

.content {
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: 10;
    @apply text-sm;
    @apply font-semibold;
}

h3 {
    text-transform: uppercase;
    margin: 0 0 0.75rem;
}

ol {
    margin-left: 1.125rem;

    li {
        margin-bottom: 0.25rem;
    }
}

.row {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    // align-items: stretch;
    gap: 1.5rem;
    margin-bottom: 3rem;

    &:last-child {
        margin-bottom: 2rem;
    }
}

.list {
    flex: auto 0 1;
    width: 100%;
    order: 2;
}

.illu {
    position: relative;
    flex: 50% 1 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    order: 1;
    width: 50%;
    margin: 0 auto;
}

:deep(.section-base-background) {
    overflow: hidden;

    .icon {
        position: absolute;
        display: block;
    }
}

@screen sm {
    .content {
        width: 80%;
        margin-left: 8%;
    }

    header {
        margin-bottom: 4rem;
    }

    .row {
        flex-flow: row nowrap;

        &.reverse {
            flex-direction: row-reverse;

            // .illu {
            //     justify-content: flex-start;
            // }
        }

        // .illu {
        //     justify-content: flex-end;
        // }
    }

    .list {
        flex: auto 0 1;
        width: 100%;
        order: 1;
    }

    .illu {
        flex: 50% 1 1;
        order: 2;
    }

    :deep(.section-base-background) {
    }
}

@screen md {
    .content {
        width: 75%;
        margin-left: 10%;
        @apply text-md;
    }

    .row {
        margin-bottom: 5rem;

        &:last-child {
            margin-bottom: 2rem;
        }
    }

    .illu {
        flex: 45% 1 1;
    }

    :deep(.section-base-background) {
    }
}

@screen lg {
    .content {
        width: 70%;
        margin-left: 17%;
    }

    .row {
        margin-bottom: 4rem;

        &:last-child {
            margin-bottom: 2rem;
        }
    }

    .illu {
        flex: 50% 1 1;
    }

    :deep(.section-base-background) {
    }
}

@screen xl {
    header {
        margin-bottom: 5rem;
    }

    .content {
        width: 65%;
        margin-left: 20%;
    }

    .illu {
        flex: 45% 1 1;
    }

    :deep(.section-base-background) {
    }
}
</style>
