<template>
    <sections-base
        class="services"
        background-class="bg-ocean-200"
        inner-class="py-big "
    >
        

        <header>
            <headline>Unsere Lösungen für Altkleider</headline>
        </header>

        <div class="cards">
            <div class="card">
                <figure>
                    <img
                        src="/images/solutions/clothes.jpg"
                        width="1250"
                        height="835"
                        alt="Altkleider"
                        loading="lazy"
                    />
                </figure>
                <div class="card-body">
                    <h2 class="card-title">Service für Haushalte</h2>
                    <p>
                        Von der Abholung mit Lastenrad oder E-Transporter bis
                        hin zum klimaneutralen Versand: Wir haben die beste
                        Lösung für deinen Standort!
                    </p>
                </div>
                <div class="card-actions justify-end">
                    <butty :to="{ name: 'old-clothes' }">
                        Jetzt kostenlos buchen
                    </butty>
                </div>
            </div>

            <div class="card">
                <figure>
                    <img
                        src="/images/solutions/clothes_office.jpg"
                        width="1250"
                        height="835"
                        alt="Altkleider Büro"
                        loading="lazy"
                    />
                </figure>
                <div class="card-body">
                    <h2 class="card-title">Service für Büros</h2>
                    <p>
                        Tut euch mit euren Kolleg:innen zusammen und startet
                        jetzt eure Altkleider-Sammelaktion im Büro! Dadurch
                        fördert ihr ganz leicht Teambuilding und CSR im
                        Büroalltag!
                    </p>
                </div>
                <div class="card-actions justify-end">
                    <butty to="/altkleider#unternehmen"
                        >Jetzt kostenlos buchen</butty
                    >
                </div>
            </div>
        </div>

        <div class="p-10"></div>

        <div class="cards">
            <div class="card">
                <figure>
                    <img
                        src="/images/solutions/clothes_box.jpg"
                        width="1250"
                        height="835"
                        alt="Altkleider Sammelbox"
                        loading="lazy"
                    />
                </figure>
                <div class="card-body">
                    <h2 class="card-title">Abgabe bei einer Sammelstelle</h2>
                    <p>
                        Von Packstation zu Bio-Laden: Finde eine Sammelstelle in
                        deiner Nähe und gib dort auch kleinere Mengen an
                        Altkleidern ab!
                    </p>
                </div>
                <div class="card-actions justify-end">
                    <butty
                        to="https://info.recyclehero.de/sammelstelle-altkleider-sammlung"
                    >
                        Jetzt Sammelstelle finden
                    </butty>
                </div>
            </div>

            <div class="card">
                <figure>
                    <img
                        src="/images/solutions/clothes_brand_dummy.jpg"
                        width="1250"
                        height="835"
                        alt="Take-Back System"
                        loading="lazy"
                    />
                </figure>
                <div class="card-body">
                    <h2 class="card-title">
                        Individuelle Lösungen für Unternehmen
                    </h2>
                    <p>
                        Wir bauen Take-Back Systeme und ermöglichen deinen
                        Kund:innen, ihre Wertstoffe wieder in den Kreislauf zu
                        bringen!
                    </p>
                </div>
                <div class="card-actions justify-end">
                    <butty to="https://info.recyclehero.de/b2b-loesungen">
                        Lösungen kennenlernen
                    </butty>
                </div>
            </div>
        </div>
    </sections-base>
</template>

<style lang="scss" scoped>
.cards {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
    gap: 1.5rem;
}

.card {
    padding: 1rem;

    figure {
        img {
            user-select: none;
        }
    }
}

.subheadline {
    @apply text-xl;
    margin-bottom: 1.5rem;
}

.card-body {
    padding: 1rem 0 0.5rem;
}

p,
ul {
    @apply text-sm;
}

:deep(.section-base-background) {
    .icon {
        position: absolute;
        display: block;
    }

 
 
    .earth {
        left: 3%;
        top: 4.5%;
        width: 10%;
        opacity: 0.1;
    }

    .smiley-happy {
        left: 1%;
        top: 96.5%;
        width: 12%;
        opacity: 0.1;
        transform: rotate(-18deg) translate(0, 0);
    }

    .shirt {
        left: 88%;
        top: 6%;
        width: 10%;
        opacity: 0.1;
        transform: rotate(23deg) translate(0, 0);
    }
}

@screen sm {
    .cards {
        flex-flow: row wrap;
    }

    .card {
        width: 35%;
        // max-width: calc(50% - 1rem);
    }

    p,
    ul {
        @apply text-sm;
    }

    .subheadline {
        @apply text-3xl;
        margin-bottom: 1.5rem;
    }

    .card-actions {
        :deep(button) {
            @apply text-2xs;
        }
    }

    :deep(.section-base-background) {
     
     
        .earth {
            top: 8.5%;
            width: 8.5%;
        }

        .smiley-happy {
            left: 2.5%;
            top: 94.5%;
            width: 7.5%;
        }

        .shirt {
            top: 6%;
            width: 8%;
        }
    }
}

@screen md {
    .card-actions {
        :deep(button) {
            @apply text-md;
        }
    }

    :deep(.section-base-background) {
     
     
        .earth {
            left: 7%;
            top: 7.5%;
            width: 8%;
        }

        .smiley-happy {
            left: 2.5%;
            top: 94.5%;
            width: 6.5%;
        }

        .shirt {
            top: 7%;
            width: 7%;
        }
    }
}

@screen lg {
    .cards {
        flex-flow: row nowrap;
    }

    .card {
        width: 33%;
        flex: auto 1 1;
        padding: 1.5rem;
    }

    .card-body {
        padding: 1rem 0 0;
    }

    p,
    ul {
        @apply text-md;
    }

    :deep(.section-base-background) {
     
     
        .earth {
            top: 10%;
            width: 7%;
        }

        .smiley-happy {
            top: 75%;
            width: 7%;
        }

        .shirt {
            top: 13%;
            width: 6%;
        }
    }
}

@screen xl {
    :deep(.section-base-background) {
     
     
        .earth {
            top: 10%;
            width: 6%;
        }

        .smiley-happy {
            left: 1.5%;
            top: 75%;
            width: 7%;
        }

        .shirt {
            top: 14%;
            width: 5%;
        }
    }
}
</style>
