<template>
    <sections-base class="social-projects" background-class="bg-ocean-200">
        <header class="">
            <headline class="">Individuelle Lösungen für Unternehmen</headline>
        </header>

        <div class="content">
            <div class="row">
                <div class="list">
                    <p>
                        Wir ermöglichen deinen Kund:innen, aktiv an der
                        Kreislaufwirtschaft teilzunehmen! Steigere deine
                        Markenbekanntheit im Bereich Nachhaltigkeit und
                        verbessere deine Kundenbindung, indem du jetzt
                        innovative Kreislauflösungen anbietest!
                    </p>

                    <div class="button-container">
                        <butty
                            class="accent shadow"
                            :to="'https://info.recyclehero.de/b2b-loesungen'"
                            >Lösungen Kennenlernen</butty
                        >
                    </div>
                </div>

                <div class="illu">
                    <figure>
                        <img
                            src="/images/solutions/clickdummy.png"
                            width="1080"
                            height="1080"
                            alt="Take-Back System"
                            loading="lazy"
                        />
                    </figure>
                </div>
            </div>
        </div>
    </sections-base>
</template>

<style lang="scss" scoped>
@import '@/assets/config';

header {
    margin-bottom: 2.5rem;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 2.5rem;
    margin-bottom: 1.5rem;
}

a.underline {
    text-decoration: underline;
}

.content {
    position: relative;
    width: 90%;
    margin: 0 auto;
    z-index: 10;
    @apply text-sm;
    @apply font-semibold;
}

h3 {
    margin: 0 0 0.75rem;
}

ol {
    margin-left: 1.125rem;

    li {
        margin-bottom: 0.25rem;
    }
}

.row {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    // align-items: stretch;
    gap: 1.5rem;
    margin-bottom: 3rem;

    &:last-child {
        margin-bottom: 2rem;
    }
}

.list {
    flex: auto 0 1;
    width: 100%;
    order: 2;
}

.illu {
    position: relative;
    flex: 50% 1 1;
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    order: 1;
    transform: scale(1.5);

    .wrapper {
        position: relative;
        display: block;
        width: 40%;
        height: auto;
        @include aspect-ratio(1);
    }

    .icon {
        color: theme('colors.ocean.700');

        &.arrow-click {
            position: absolute;
            left: -15%;
            top: 6%;
            width: 25%;
            transform: translate(0%, 0) rotate(-25deg);
        }

        &.scribbles-star1 {
            position: absolute;
            left: -12%;
            top: 11%;
            width: 24%;
            transform: translate(0%, 0);
        }

        &.smiley-happy,
        &.smiley-sad {
            position: absolute;
            left: 0%;
            top: 10%;
            width: 100%;
            height: 80%;
        }
    }
}

:deep(.section-base-background) {
    overflow: hidden;

    .icon {
        position: absolute;
        display: block;
    }

    .fist {
        right: 3%;
        top: 2%;
        width: 8%;
        opacity: 0.1;
        transform: rotate(-15deg);
    }
}

@screen sm {
    .content {
        width: 90%;
        margin-left: 8%;
    }

    header {
        margin-bottom: 4rem;
    }

    .row {
        flex-flow: row nowrap;
    }

    .list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: auto 0 1;
        width: 100%;
        order: 2;
    }

    .illu {
        flex: 50% 1 1;
        order: 2;

        .wrapper {
            width: 60%;
        }
    }

    :deep(.section-base-background) {
    }
}

@screen md {
    .content {
        width: 85%;
        margin-left: 10%;
        @apply text-md;
    }

    .row {
        margin-bottom: 5rem;

        &:last-child {
            margin-bottom: 2rem;
        }
    }

    .illu {
        flex: 45% 1 1;

        .wrapper {
            width: 70%;
        }
    }

    :deep(.section-base-background) {
    }
}

@screen lg {
    .content {
        width: 75%;
        margin-left: 17%;
    }

    .row {
        margin-bottom: 4rem;

        &:last-child {
            margin-bottom: 2rem;
        }
    }

    .illu {
        flex: 50% 1 1;

        .wrapper {
            width: 60%;
        }
    }

    :deep(.section-base-background) {
    }
}

@screen xl {
    header {
        margin-bottom: 5rem;
    }

    .content {
        width: 75%;
        margin-left: 20%;
    }

    .illu {
        flex: 60% 1 1;

        .wrapper {
            width: 50%;
        }
    }

    :deep(.section-base-background) {
    }
}
</style>
